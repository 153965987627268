export const Types = {
  CLEAR_STORE: '[Rooms] Clear Store',
  UPDATE_ROOMS: '[Rooms] Update Rooms',
  JOIN_ROOM: '[Rooms] Join Room',
  LEAVE_ROOM: '[Rooms] Leave Room',
  ADD_MESSAGE: '[Rooms] Add Message',
  UPDATE_GAMES: '[Rooms] Update Games',
  USER_JOINED: '[Rooms] User Joined',
  USER_LEFT: '[Rooms] User Left',
  SORT_GAMES: '[Rooms] Sort Games'
};

export const MAX_ROOM_MESSAGES = 1000;
